// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s alternate-reverse cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.App-header {
  background-color: transparent;
  /* min-height: 100vh; */
  display: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(0.7);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,6FAA6F;EAC/F;AACF;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 20vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 4s alternate-reverse cubic-bezier(0.68, -0.55, 0.265, 1.55);\n  }\n}\n\n.App-header {\n  background-color: transparent;\n  /* min-height: 100vh; */\n  display: absolute;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: scale(0.9);\n  }\n  to {\n    transform: scale(0.7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
